import type { JSX } from 'react';
import { useCallback, useContext, useState } from 'react';
import type { Option } from '../../../../components/Dropdown/Dropdown';
import { useTranslation } from '../../../../lang';
import LandingSearch from '../../../../templates/LandingV5/components/LandingSearch/LandingSearch';
import { LandingSearchContext } from '../../../../templates/LandingV5/components/LandingSearch/store/context';

const getSortBySearchedValue = (data: Option[], input: string) => {
  const first: Option[] = [];
  const others: Option[] = [];
  for (let i = 0; i < data.length; i += 1) {
    if (data[i]!.label?.toLowerCase().startsWith(input)) {
      first.push(data[i]!);
    } else {
      others.push(data[i]!);
    }
  }
  first.sort();
  others.sort();
  return first.concat(others);
};

/**
 * SearchChannels
 *
 * Dropdown search component
 * @returns {node}
 */
function SearchChannels(): JSX.Element {
  const {
    searchResults: channels,
    searchInput: searchChannelsInput,
    updateSearchInput: updateSearchChannelsInput,
    updateSelectedOption: updateSelectedChannel,
  } = useContext(LandingSearchContext);

  const [results, setResults] = useState<Option[]>([]);
  const { t } = useTranslation();

  const handleChange = useCallback(
    (value: string) => {
      updateSearchChannelsInput(value);
      if (value.trim() !== '') {
        const searchResults = channels.filter(({ label }) =>
          label.toLowerCase().includes(value.toLowerCase())
        );
        const sortedResults = getSortBySearchedValue(
          searchResults,
          value.toLowerCase()
        );

        setResults(sortedResults);
      } else {
        setResults([]);
      }
    },
    [channels, updateSearchChannelsInput, setResults]
  );

  const selectOption = (option: Option) => {
    updateSelectedChannel(option);

    if (results.length > 0) {
      updateSearchChannelsInput('');
      setResults([]);
    }
  };

  return (
    <LandingSearch
      handleChange={handleChange}
      landingSearchInput={searchChannelsInput}
      options={results}
      placeholder={t('TvGuide.search.placeholder')}
      selectOption={selectOption}
    />
  );
}

export default SearchChannels;
