import { DIMENSIONS } from '@canalplus/dive-utils';
import { Heading, ImageType } from '@canalplus/mycanal-sharedcomponent';
import classNames from 'classnames/bind';
import type { JSX } from 'react';
import { useInvariantSelector } from '../../helpers/hooks/useInvariantSelector';
import { useOnFocusable } from '../../helpers/hooks/useOnFocusable';
import type { FocusManager } from '../../helpers/oneNavigation/FocusManager';
import { applicationResizeSelector } from '../../store/slices/application-selectors';
import type { CoverV5Data } from '../IdentityCover/types';
import TemplateHeaderCanal from '../TemplateHeader/TemplateHeader';
import styles from './HeadingIdentityV5.css';

const cx = classNames.bind(styles);

type HeadingIdentityV5Props = {
  cover: CoverV5Data;
  title?: string;
  focusManager?: FocusManager;
};

function HeadingIdentityV5({
  cover: {
    urlLogoType,
    altLogoType,
    urlLogoChannel,
    altLogoChannel,
    description,
    image,
  },
  title,
  focusManager,
}: HeadingIdentityV5Props): JSX.Element {
  const resizeMode = useInvariantSelector(applicationResizeSelector);
  const isCoverImage = !!image;

  const isLogoDefined = !!urlLogoType || !!urlLogoChannel;

  useOnFocusable(focusManager);

  return (
    <div
      className={cx('headingIdentityV5', {
        'headingIdentityV5--withcover': isCoverImage,
      })}
      data-testid="HeadingIdentityV5"
    >
      {title && !isLogoDefined && (
        <TemplateHeaderCanal
          title={title}
          displayBigTitle
          theme={{
            templateHeader__title: cx('headingIdentityV5__title'),
          }}
        />
      )}
      {urlLogoType && (
        <div
          className={cx(
            'headingIdentityV5__logo',
            'headingIdentityV5__logo--logotype'
          )}
        >
          <ImageType
            URLImage={{ default: urlLogoType }}
            className={cx('headingIdentityV5__logo__image')}
            altImage={altLogoType}
            dimensions={DIMENSIONS.LOGOTYPE_IDENTITY_V5}
            isTvDevice={$_BUILD_RENDERMODE_CSR}
            resizeMode={resizeMode}
            isArtDirection
          />
        </div>
      )}
      {urlLogoChannel && (
        <div
          className={cx(
            'headingIdentityV5__logo',
            'headingIdentityV5__logo--logochannel'
          )}
        >
          <ImageType
            URLImage={{ default: urlLogoChannel }}
            className={cx('headingIdentityV5__logo__image')}
            altImage={altLogoChannel}
            dimensions={DIMENSIONS.LOGO_CHANNEL_IDENTITY_V5}
            isTvDevice={$_BUILD_RENDERMODE_CSR}
            resizeMode={resizeMode}
            isArtDirection
          />
        </div>
      )}
      {description && (
        <div className={cx('headingIdentityV5__subtitle')}>
          <Heading text={description} level={3} />
        </div>
      )}
    </div>
  );
}

export default HeadingIdentityV5;
